<template>
  <v-container>
    <v-text-field v-model="search" placeholder="Suche" filled clearable hide-details/>

    <v-list>
      <v-list-item
        v-for="user in usersList"
        :key="user.id"
        _two-line
        @click="displayUser(user)"
      >
        <v-list-item-avatar>
          <user-avatar :user="user"/>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ user.forename }} {{ user.lastname }}</v-list-item-title>
          <v-list-item-subtitle>{{ user.name !== user.fullname ? user.name : '' }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
import UserAvatar from '@/components/base/UserAvatar'

export default {
  name: 'UsersView',
  components: { UserAvatar },
  data () {
    return {
      search: null
    }
  },
  computed: {
    users () {
      return Object.keys(this.$root.users)
        .map(userId => ({ id: userId, ...this.$root.users[userId] }))
        .filter(user => !user._disabled) // remove disabled
        .map(user => ({
          ...user,
          fullname: (user.forename || '') + ' ' + (user.lastname || '').trim() || (user.name || ''),
          search: ((user.name || '') + '_' + (user.forename || '') + '_' + (user.lastname || '') + '_' + (user.address?.city || '')).toLowerCase()
        }))
        .sort((a, b) => a.fullname < b.fullname ? -1 : 1)
    },
    usersList () {
      return this.search
        ? this.users.filter(user => user.search.indexOf(this.search.toLowerCase()) > -1)
        : this.users
    }
  },
  methods: {
    displayUser (user) {
      this.$root.displayUserId = user.id
    }
  }
}
</script>
